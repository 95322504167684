











import { Component, Vue } from 'vue-property-decorator';
import { appConfigModule } from '@/store/modules/appConfig';
@Component({
    name: 'NetworkLineCard',
    components: {}
})
export default class NetworkLineCard extends Vue {
    // public list = [
    //     { Text: '默認', Value: '0' },
    //     { Text: '線路1', Value: '1' },
    //     { Text: '線路2', Value: '2' }
    // ];

    public get networkLine() {
        return appConfigModule.networkLine;
    }

    public async handleSetLanguage(lang: string) {
        await appConfigModule.setNetworkLine(lang);
    }

    public get getNetWork() {
        const result = appConfigModule.appConfig.PcAPi.map((url, index) => ({
            Text: index === 0 ? '默認' : `線路${index}`,
            Value: String(index)
        }));
        return result;
    }
}
